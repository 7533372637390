import { designImage } from '@/assets';
import { Conditional, ResponsiveLazyImage, SectionElement } from '@/components';
import AcronLabelFiatToroUltra from '@/components/svg-components/AcronLabelFiatToroUltra';
import { dataMenuLabels } from '@/data/menu.data';
import { FC } from 'react';
import styles from './photo-section.module.scss';
import Image from 'next/image';
import AcronLabelFiatToroUltraMobile from "@components/svg-components/AcronLabelFiatToroUltraMobile";

const PhotoSection: FC = () => {
  const reference = dataMenuLabels().find(
    (c) => c.slug === 'design-incomparavel'
  );

  return (
    <SectionElement
      id="photoSection"
      className={styles.container}
      navReference={reference}
    >
      <div className={styles.content}>
        <div className={styles.box} style={{ position: 'relative' }}>
          <div className={styles.textBox}>
            <div className={styles.header}>
              <Conditional notOn="mobile">
                <div>
                  <AcronLabelFiatToroUltra/>
                </div>
              </Conditional>

              <Conditional notOn="desktop">
                <div>
                  <div>
                    <AcronLabelFiatToroUltraMobile/>
                  </div>
                </div>
              </Conditional>

            </div>
            <p>
              Apaixone-se por cada detalhe <br/>
              do marcante e robusto novo <br />
              design da <em>Nova Fiat Toro.</em>
            </p>
          </div>
        </div>
      </div>

      <Conditional
        mobile={
          <div className={styles.designBackground}>
            <Image
              alt="Fiat Toro Ultra da cor branca com detalhe vermelho na grade frontal em chão branco e fundo preto."
              title="Nova Fiat Toro Ultra"
              src={designImage.mobile.fullPath2x}
              layout="fill"
            />
          </div>
        }
        desktop={
          <div className={styles.designBackground}>
            <Image
              alt="Fiat Toro Ultra da cor branca com detalhe vermelho na grade frontal em chão branco e fundo preto."
              title="Nova Fiat Toro Ultra"
              src={designImage.desktop.fullPath2x}
              layout="fill"
            />
          </div>
        }
      />
      {/* <BannerBottomOrnament className={styles.desktopOrnament} /> */}
    </SectionElement>
  );
};

export default PhotoSection;
