import { FC } from "react";
import styles from "./footer.module.scss";
import { brand } from "@/assets";
import { dataSocial } from "@/data/footer.data";
import FooterBrand from "@/components/svg-components/FooterBrand";
import { CTAButton, Conditional } from "@/components";
import { dataMenuButtonsMobile } from "@/data/menu.data";
import DataLayer from "@/utils/Datalayer";

const Footer: FC = () => {
  return (
    <footer className={styles.container}>
      <Conditional
        desktop={
          <div className={styles.letsDeal}>
            <h2>
              VAMOS <em>NEGOCIAR?</em>
            </h2>
            <div className={styles.ctas}>
              <CTAButton
                href={dataMenuButtonsMobile[2].url}
                text={dataMenuButtonsMobile[2].label}
                title={dataMenuButtonsMobile[2].label}
                className={styles.ctaButton}
                handleClick={() =>
                  DataLayer.clickEvent({
                    element: dataMenuButtonsMobile[2].label,
                    elementCategory: "cta",
                    pageSection: "vamos-negociar",
                    pageSubsection: "ctas",
                  })
                }
              />
              <CTAButton
                href={dataMenuButtonsMobile[3].url}
                text={dataMenuButtonsMobile[3].label}
                title={dataMenuButtonsMobile[3].label}
                className={styles.ctaButton}
                handleClick={() =>
                  DataLayer.clickEvent({
                    element: dataMenuButtonsMobile[3].label,
                    elementCategory: "cta",
                    pageSection: "vamos-negociar",
                    pageSubsection: "ctas",
                  })
                }
              />
              <CTAButton
                href={dataMenuButtonsMobile[4].url}
                text={dataMenuButtonsMobile[4].label}
                title={dataMenuButtonsMobile[4].label}
                handleClick={() =>
                  DataLayer.clickEvent({
                    element: dataMenuButtonsMobile[4].label,
                    elementCategory: "cta",
                    pageSection: "vamos-negociar",
                    pageSubsection: "ctas",
                  })
                }
                className={styles.ctaButton}
              />
            </div>
          </div>
        }
        mobile={
          <div className={styles.ctas}>
            {dataMenuButtonsMobile.map(({ label, url }) => (
              <CTAButton
                key={label}
                href={url}
                text={label}
                title={label}
                handleClick={() =>
                  DataLayer.clickEvent({
                    element: label,
                    elementCategory: "cta",
                    pageSection: "footer",
                    pageSubsection: "ctas",
                  })
                }
                className={styles.ctaButton}
              />
            ))}
          </div>
        }
      />

      <div className={styles.social}>
        <div className={styles.footerIcon}>
          <div className={styles.socialMedia}>
            <div className={styles.footerSocialMedia}>
              {dataSocial.map((social) => {
                  const tracking = () => {
                    DataLayer.clickEvent({
                      element: social.title,
                      elementCategory: "icone",
                      pageSection: "footer",
                      pageSubsection: "social",
                    });
                  };
                return (
                  <a
                    key={social.id}
                    target="_blank"
                    href={social.url}
                    title={social.title}
                    data-before={social.icon}
                    onClick={() => {
                      tracking();
                    }}
                    onAuxClick={() => {
                      tracking();
                    }}
                  >
                    {social.title}
                  </a>
                );
              })}
            </div>
          </div>
        </div>

        <div className={styles.footerText}>
          <p>
            A disponibilidade de itens de série, opcionais e acessórios pode
            variar de acordo com a versão escolhida. Verifique o Monte seu
            Carro. Imagens meramente ilustrativas.
          </p>
          <br />
          <FooterBrand className={styles.brandFooter} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
