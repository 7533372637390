import {
  ArrowButton,
  Conditional,
  ConnectMeMobile,
  ResponsiveLazyImage,
  SectionElement,
  VimeoEmbed,
} from '@/components';
import { Context } from '@/context';
import { connectMe } from '@/data/connect-me.data';
import { dataMenuLabels } from '@/data/menu.data';
import getRangeOfEntries from '@/utils/getRangeOfEntries';
import scssStyles from '@/utils/scssStyles';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useContext, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import SwiperCore, { A11y, Keyboard, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './connectMe.module.scss';
import DataLayer from '@/utils/Datalayer';
import YouTubeEmbed from '@/components/youtube-embed';

SwiperCore.use([A11y, Keyboard, Thumbs]);

const reference = dataMenuLabels().find((c) => c.slug === 'connect-me');

export const ConnectMe: FC = observer(() => {
  const { state, dispatch } = useContext(Context);
  const [controller, setController] = useState<SwiperCore>();
  const pageSubsection = 'fiat-connect-me';

  const findIndex = () => {
    return connectMe.findIndex(
      (g) => g.id === state.layout.selectedConnectMeImage?.id
    );
  };

  const swipeHandlers = useSwipeable({
    trackMouse: true,
    onSwipedLeft: () => {
      DataLayer.swipeEvent({
        element: 'anterior',
        elementCategory: 'imagem',
        pageSection: 'conteudo',
        pageSubsection,
      });
      next();
    },
    onSwipedRight: () => {
      DataLayer.swipeEvent({
        element: 'proximo',
        elementCategory: 'imagem',
        pageSection: 'conteudo',
        pageSubsection,
      });
      previous();
    },
  });

  const updateIndex = useCallback(
    (n: number) => {
      dispatch({
        type: 'SET_CONNECTME_IMAGE',
        payload: { selectedConnectMeImage: connectMe[n] },
      });
      if (controller && typeof n === 'number') {
        controller?.slideTo(n);
      }
    },
    [controller, dispatch]
  );

  const next = () => {
    const index = findIndex();
    const nIndex = index + 1 > connectMe.length - 1 ? 0 : index + 1;
    updateIndex(nIndex);
  };
  const previous = () => {
    const index = findIndex();
    const nIndex = index - 1 < 0 ? connectMe.length - 1 : index - 1;
    updateIndex(nIndex);
  };

  const bulletProps = getRangeOfEntries(findIndex(), connectMe);

  const Controls: FC = () => {
    return (
      <Conditional
        desktop={
          <div className={styles.controls}>
            <div className={styles.bullets}>
              {connectMe.map((c, index) => {
                return (
                  <button
                    key={`bullet-${c.id}`}
                    title={c.title}
                    className={scssStyles([
                      styles.bullet,
                      state.layout.selectedConnectMeImage?.id === c.id
                        ? styles.active
                        : '',
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `item-${index + 1}`,
                        elementCategory: 'icone',
                        pageSection: 'conteudo',
                        pageSubsection,
                      });
                      dispatch({
                        type: 'SET_CONNECTME_IMAGE',
                        payload: { selectedConnectMeImage: c },
                      });
                    }}
                  >
                    <span />
                  </button>
                );
              })}
            </div>
            <div className={styles.arrowBox}>
              <ArrowButton
                className={styles.arrowButton}
                title={'Anterior'}
                previous
                handleClick={() => {
                  DataLayer.clickEvent({
                    element: 'anterior',
                    elementCategory: 'icone',
                    pageSection: 'conteudo',
                    pageSubsection,
                  });
                  previous();
                }}
              />
              <ArrowButton
                className={styles.arrowButton}
                title={'Próximo'}
                handleClick={() => {
                  DataLayer.clickEvent({
                    element: 'proximo',
                    elementCategory: 'icone',
                    pageSection: 'conteudo',
                    pageSubsection,
                  });
                  next();
                }}
              />
            </div>
          </div>
        }
        mobile={
          <div className={styles.controls}>
            <ArrowButton
              className={styles.arrowButton}
              title={'Anterior'}
              previous
              handleClick={() => {
                DataLayer.clickEvent({
                  element: 'anterior',
                  elementCategory: 'icone',
                  pageSection: 'conteudo',
                  pageSubsection,
                });
                previous();
              }}
            />
            <div className={styles.bullets}>
              {!!bulletProps.before &&
                Array.from(Array(bulletProps.before).keys())
                  .slice(0, 2)
                  .map((_, i) => (
                    <button
                      key={`smaller-bullet-connect-me-before-${i}`}
                      className={scssStyles([styles.bullet])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `item-${findIndex() - 2}`,
                          elementCategory: 'icone',
                          pageSection: 'conteudo',
                          pageSubsection,
                        });
                        updateIndex(findIndex() - 2);
                      }}
                    >
                      <span />
                    </button>
                  ))}
              {connectMe.map((c, index) => {
                if (bulletProps.range.indexOf(index) === -1) return null;
                return (
                  <button
                    key={`bullet-${c.id}`}
                    title={c.title}
                    className={scssStyles([
                      styles.bullet,
                      state.layout.selectedConnectMeImage?.id === c.id
                        ? styles.active
                        : '',
                    ])}
                    onClick={() => {
                      DataLayer.clickEvent({
                        element: `item-${index + 1}`,
                        elementCategory: 'icone',
                        pageSection: 'conteudo',
                        pageSubsection,
                      });
                      dispatch({
                        type: 'SET_CONNECTME_IMAGE',
                        payload: { selectedConnectMeImage: c },
                      });
                    }}
                  >
                    <span />
                  </button>
                );
              })}
              {!!bulletProps.after &&
                Array.from(Array(bulletProps.after).keys())
                  .slice(0, 2)
                  .map((_, i) => (
                    <button
                      key={`smaller-bullet-connect-me-after-${i}`}
                      className={scssStyles([styles.bullet])}
                      onClick={() => {
                        DataLayer.clickEvent({
                          element: `item-${findIndex() + 2}`,
                          elementCategory: 'icone',
                          pageSection: 'conteudo',
                          pageSubsection,
                        });
                        updateIndex(findIndex() + 2);
                      }}
                    >
                      <span />
                    </button>
                  ))}
            </div>
            <ArrowButton
              className={styles.arrowButton}
              title={'Próximo'}
              handleClick={() => {
                DataLayer.clickEvent({
                  element: 'proximo',
                  elementCategory: 'icone',
                  pageSection: 'conteudo',
                  pageSubsection,
                });
                next();
              }}
            />
          </div>
        }
      />
    );
  };

  return (
    <>
      <SectionElement
        id="ConnectMe"
        className={styles.container}
        navReference={reference}
      >
        <Conditional
          mobile={
            <>
              <ResponsiveLazyImage
                alt={state.layout.selectedConnectMeImage.title}
                src={state.layout.selectedConnectMeImage.background.fullPath}
                src2={state.layout.selectedConnectMeImage.background.fullPath2x}
                src3={state.layout.selectedConnectMeImage.background.fullPath3x}
                className={styles.backgroundImage}
              />
              <div className={styles.connectMeContainer}>
                <ConnectMeMobile />
                <div className={styles.aligner}>
                  <div className={styles.mainImage}>
                    <AnimatePresence>
                      {connectMe.map((g) => {
                        const isMobile = !state.layout.isDesktop;
                        if (state.layout.selectedConnectMeImage?.id !== g.id)
                          return null;
                        return (
                          //@ts-ignore
                          <motion.div
                            key={`main-connect-me-${g.id}`}
                            {...swipeHandlers}
                            initial={{
                              opacity: 0,
                              y: isMobile ? -100 : 0,
                              x: !isMobile ? 100 : 0,
                            }}
                            animate={{
                              opacity: 1,
                              y: 0,
                              x: 0,
                              scale: 1,
                            }}
                            exit={{
                              opacity: 0,
                              y: isMobile ? 100 : 0,
                              x: !isMobile ? 100 : 0,
                            }}
                            transition={{
                              duration: 0.2,
                            }}
                          >
                            {g.video ? (
                              <iframe
                                src={`https://www.youtube.com/embed/${g.videoId}?&enablejsapi=1&rel=0&origin=https://toro.fiat.com.br`}
                                title={g.title}
                                width="100%"
                                height="100%"
                                id={g.title}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                              ></iframe>
                            ) : (
                              <ResponsiveLazyImage
                                alt={g.title}
                                title={g.title}
                                src={g.image.fullPath}
                                src2={g.image.fullPath2x}
                                src3={g.image.fullPath3x}
                              />
                            )}
                          </motion.div>
                        );
                      })}
                    </AnimatePresence>
                  </div>

                  <div className={styles.thumbs}>
                    <Swiper
                      observer
                      observeParents
                      parallax
                      onSwiper={(e) => setController(e)}
                      controller={
                        controller ? { control: controller } : undefined
                      }
                      // className={styles.swiperMobile}
                    >
                      <SwiperSlide
                        key={`connect-me-thumb-${state.layout.selectedConnectMeImage.id}`}
                        className={styles.swiperSlide}
                      >
                        <div className={styles.description}>
                          <h2>
                            {state.layout.selectedConnectMeImage.titleElement}{' '}
                          </h2>
                          <Conditional
                            desktop={
                              state.layout.selectedConnectMeImage
                                .descriptionDesktop
                            }
                            mobile={
                              state.layout.selectedConnectMeImage
                                .descriptionMobile
                            }
                          />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
                {/* Controls */}
                <Conditional notOn="desktop">
                  <div className={styles.controls}>
                    <ArrowButton
                      className={styles.arrowButton}
                      title={'Anterior'}
                      previous
                      handleClick={() => {
                        DataLayer.clickEvent({
                          element: 'anterior',
                          elementCategory: 'icone',
                          pageSection: 'conteudo',
                          pageSubsection,
                        });
                        previous();
                      }}
                    />
                    <div className={styles.bullets}>
                      {!!bulletProps.before &&
                        Array.from(Array(bulletProps.before).keys())
                          .slice(0, 2)
                          .map((_, i) => (
                            <button
                              key={`smaller-bullet-connect-me-before-${i}`}
                              className={scssStyles([styles.bullet])}
                              onClick={() => {
                                DataLayer.clickEvent({
                                  element: `item-${findIndex() - 2}`,
                                  elementCategory: 'icone',
                                  pageSection: 'conteudo',
                                  pageSubsection,
                                });
                                updateIndex(findIndex() - 2);
                              }}
                            >
                              <span />
                            </button>
                          ))}
                      {connectMe.map((c, index) => {
                        if (bulletProps.range.indexOf(index) === -1)
                          return null;
                        return (
                          <button
                            key={`bullet-${c.id}`}
                            title={c.title}
                            className={scssStyles([
                              styles.bullet,
                              state.layout.selectedConnectMeImage?.id === c.id
                                ? styles.active
                                : '',
                            ])}
                            onClick={() => {
                              DataLayer.clickEvent({
                                element: `item-${index + 1}`,
                                elementCategory: 'imagem',
                                pageSection: 'conteudo',
                                pageSubsection,
                              });
                              dispatch({
                                type: 'SET_CONNECTME_IMAGE',
                                payload: { selectedConnectMeImage: c },
                              });
                            }}
                          >
                            <span />
                          </button>
                        );
                      })}
                      {!!bulletProps.after &&
                        Array.from(Array(bulletProps.after).keys())
                          .slice(0, 2)
                          .map((_, i) => (
                            <button
                              key={`smaller-bullet-connect-me-after-${i}`}
                              className={scssStyles([styles.bullet])}
                              onClick={() => {
                                DataLayer.clickEvent({
                                  element: `item-${findIndex() + 2}`,
                                  elementCategory: 'icone',
                                  pageSection: 'conteudo',
                                  pageSubsection,
                                });
                                updateIndex(findIndex() + 2);
                              }}
                            >
                              <span />
                            </button>
                          ))}
                    </div>
                    <ArrowButton
                      className={styles.arrowButton}
                      title={'Próximo'}
                      handleClick={() => {
                        DataLayer.clickEvent({
                          element: 'proximo',
                          elementCategory: 'icone',
                          pageSection: 'conteudo',
                          pageSubsection,
                        });
                        next();
                      }}
                    />
                  </div>
                </Conditional>
              </div>
            </>
          }
          desktop={
            <>
              <div className={styles.connectMeContainer}>
                <div className={styles.aligner}>
                  <div className={styles.thumbs}>
                    <ConnectMeMobile className={styles.connectMeLogoDesktop} />
                    <ResponsiveLazyImage
                      alt={state.layout.selectedConnectMeImage.title}
                      src={
                        state.layout.selectedConnectMeImage.background.fullPath
                      }
                      src2={
                        state.layout.selectedConnectMeImage.background
                          .fullPath2x
                      }
                      src3={
                        state.layout.selectedConnectMeImage.background
                          .fullPath3x
                      }
                      className={styles.backgroundImage}
                    />
                    <Swiper
                      observer
                      observeParents
                      parallax
                      onSwiper={(e) => setController(e)}
                      controller={
                        controller ? { control: controller } : undefined
                      }
                      // className={styles.swiperMobile}
                    >
                      <SwiperSlide
                        key={`connect-me-thumb-${state.layout.selectedConnectMeImage.id}`}
                        className={styles.swiperSlide}
                      >
                        <div className={styles.description}>
                          <h2>
                            {state.layout.selectedConnectMeImage.titleElement}{' '}
                          </h2>
                          <Conditional
                            desktop={
                              state.layout.selectedConnectMeImage
                                .descriptionDesktop
                            }
                            mobile={
                              state.layout.selectedConnectMeImage
                                .descriptionMobile
                            }
                          />
                        </div>
                      </SwiperSlide>
                    </Swiper>
                    <Controls />
                  </div>
                  <div className={styles.mainImage}>
                    <AnimatePresence>
                      {connectMe.map((g) => {
                        const isMobile = !state.layout.isDesktop;
                        if (state.layout.selectedConnectMeImage?.id !== g.id)
                          return null;
                        return (
                          //@ts-ignore
                          <motion.div
                            key={`main-connect-me-${g.id}`}
                            {...swipeHandlers}
                            initial={{
                              opacity: 0,
                              y: isMobile ? -100 : 0,
                              x: !isMobile ? 100 : 0,
                            }}
                            animate={{
                              opacity: 1,
                              y: 0,
                              x: 0,
                              scale: 1,
                            }}
                            exit={{
                              opacity: 0,
                              y: isMobile ? 100 : 0,
                              x: !isMobile ? 100 : 0,
                            }}
                            transition={{
                              duration: 0.2,
                            }}
                          >
                            {g.video ? (
                              <iframe
                                src={`https://www.youtube.com/embed/${g.videoId}?&enablejsapi=1&rel=0&origin=https://toro.fiat.com.br`}
                                title={g.title}
                                width="100%"
                                height="100%"
                                id={g.title}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                              ></iframe>
                            ) : (
                              <ResponsiveLazyImage
                                alt={g.title}
                                title={g.title}
                                src={g.image.fullPath}
                                src2={g.image.fullPath2x}
                                src3={g.image.fullPath3x}
                              />
                            )}
                          </motion.div>
                        );
                      })}
                    </AnimatePresence>
                  </div>
                </div>
                {/* Controls */}
                <Conditional notOn="desktop">
                  <Controls />
                </Conditional>
              </div>
            </>
          }
        />
      </SectionElement>
    </>
  );
});
