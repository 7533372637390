import { FC, useState } from "react";
// @ts-ignore
import ReactPannellum from "react-pannellum";
import { SectionElement } from "@/components";
import { panoramaImage, panoramaImagePreview } from "@/assets";
import { dataMenuLabels } from "@/data/menu.data";
import styles from "./Panorama.module.scss";


export const Panorama: FC = () => {
    const reference = dataMenuLabels().find((c) => c.slug === "panorama");
    const [show360, setShow360] = useState<boolean>(false);
    const config = {
        autoLoad: true,
        showControls: false,
        mouseZoom: false,
        backgroundColor: [255, 255, 255],
        pitch: -20,
        yaw: -5,
        hfov: 170
    }

    return (
        <>
            <SectionElement
                id="panorama"
                className={styles.container}
                navReference={reference}
                noPadding
            >
                {!show360 ? (
                    <div
                        className={styles.holder}
                        onClick={() => {
                            setShow360(true);
                        }}
                        style={{ backgroundImage: `url(${panoramaImagePreview})` }}
                    >
                        <h2 className={styles.title}>
                            Por fora imponente e robusta,<br />
                            Por dentro muito luxuosa e inovadora.
                        </h2>
                        <p className={styles.drag}>Arraste para interagir</p>
                    </div>
                ) : (
                    <ReactPannellum
                        config={config}
                        id="1"
                        sceneId="firstScene"
                        imageSource={panoramaImage}
                        preview={ panoramaImagePreview }
                        className={styles.image}
                    />
                )}
            </SectionElement>
        </>
    );
};