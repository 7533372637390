import { FC } from "react";

const FooterBrand:FC<{className:string}> = ({className}) => {
  return (
    <svg
      id="img"
      xmlns="http://www.w3.org/2000/svg"
      width="102"
      height="66"
      viewBox="0 0 102 66"
      className={className}
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="mask" width="102" height="66" fill="#0aff00" />
        </clipPath>
      </defs>
      <g id="nome-feature" clipPath="url(#clip-path)">
        <g id="Fiat_Flag" data-name="Fiat Flag" transform="translate(6.675)">
          <path
            id="Caminho_149"
            data-name="Caminho 149"
            d="M518.08,300.235l-21.415,66h9.913l21.415-66Z"
            transform="translate(-476.837 -300.235)"
            fill="#edede3"
          />
          <path
            id="Caminho_150"
            data-name="Caminho 150"
            d="M337.984,300.235l-21.415,66h9.912l21.415-66Z"
            transform="translate(-316.57 -300.235)"
            fill="#2c8d54"
          />
          <path
            id="Caminho_151"
            data-name="Caminho 151"
            d="M698.175,300.235l-21.415,66h9.913l21.415-66Z"
            transform="translate(-637.107 -300.235)"
            fill="#edede3"
          />
          <path
            id="Caminho_152"
            data-name="Caminho 152"
            d="M878.271,300.235l-21.415,66h9.913l21.415-66Z"
            transform="translate(-797.376 -300.235)"
            fill="#fc1430"
          />
        </g>
      </g>
    </svg>
  );
};

export default FooterBrand;
