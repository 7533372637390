import { Cards, Display, SectionElement } from '@/components';
import {
  dataHero,
  heroFirstAltTag,
  heroFirstBackground,
  heroFirstTitleTag,
  IHero,
} from '@/data/hero.data';
import { dataMenuLabels } from '@/data/menu.data';
import scssStyles from '@/utils/scssStyles';
import React, { FC } from 'react';
import styles from './HeroDesktop.module.scss';
import HeroTitle from '@/components/svg-components/HeroTitle';
import { heroImages } from '@/assets';
import DescriptionHeroOrnament from '@/components/svg-components/descriptionHeroOrnament';

export const HeroDesktop: FC = () => {
  const previous = React.useRef<(() => void) | null>(null);
  const next = React.useRef<(() => void) | null>(null);

  const items: IHero[] | any = [];

  items.push({
    body: (
      <div className={scssStyles([styles.slides])}>
        <div
          className={styles.fullBgGradient}
          aria-label={'Toro'}
          title={'Nova Fiat Toro'}
        />
        <div className={styles.content}>
          <img
            className={styles.heroTitle}
            alt="Nova Fiat Toro"
            src={heroImages.heroTitle}
          />
        </div>
      </div>
    ),
    background: {
      src: heroFirstBackground,
      alt: heroFirstAltTag,
      title: heroFirstTitleTag,
    },
  });

  dataHero.map((item) =>
    items.push({
      body: (
        <div className={scssStyles([styles.slides])}>
          <div
            className={styles.fullBgGradient}
            aria-label={item.titleTag}
            title={item.titleTag}
          />
          <div className={styles.content}>
            <div className={styles.descriptionContent}>
              <div className={styles.descriptionLayoutTwo}>
                <h2 className={styles.tltBottom}>{item.titleStories}</h2>
                <div className={styles.descriptionBox}>
                  <DescriptionHeroOrnament
                    className={styles.descriptionOrnament}
                  />
                  <p>{item.description}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
      background: {
        src: item.backgrounds[0],
        alt: item.altTag,
        title: item.title,
      },
    })
  );

  return (
    <SectionElement
      id="desktopDisplay"
      className={styles.container}
      navReference={dataMenuLabels()[0]}
      handleKeyDown={(e, isVisible) => {
        if (isVisible) {
          if (e.key === 'ArrowLeft') {
            previous?.current && previous?.current();
          } else if (e.key === 'ArrowRight') {
            next?.current && next?.current();
          }
        }
      }}
    >
      <Display
        previousEvent={(e) => {
          previous.current = e;
        }}
        nextEvent={(e) => {
          next.current = e;
        }}
        items={items}
      />
      <Cards
        items={dataHero.map((d) => ({
          ...d,
          src: d.cardSrc,
          title: d.cardTitle,
        }))}
      />
    </SectionElement>
  );
};
