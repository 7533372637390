import { FC } from "react";

const SotiesDescriptionOrnament: FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.695"
      height="32.541"
      viewBox="0 0 44.695 32.541"
    >
      <g id="flag-color" transform="translate(0.671 0.5)">
        <g id="Grupo_462" data-name="Grupo 462" transform="translate(0 0)">
          <path
            id="Caminho_134"
            data-name="Caminho 134"
            d="M13.766,0,4.354,31.541H0L9.41,0Z"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_463" data-name="Grupo 463" transform="translate(9.886 0)">
          <path
            id="Caminho_135"
            data-name="Caminho 135"
            d="M410.464,0l-9.41,31.541H396.7L406.11,0Z"
            transform="translate(-396.7)"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_464" data-name="Grupo 464" transform="translate(19.703 0)">
          <path
            id="Caminho_136"
            data-name="Caminho 136"
            d="M804.364,0l-9.41,31.541H790.6L800.01,0Z"
            transform="translate(-790.6)"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_465" data-name="Grupo 465" transform="translate(29.587 0)">
          <path
            id="Caminho_137"
            data-name="Caminho 137"
            d="M1200.967,0l-9.412,31.541H1187.2L1196.61,0Z"
            transform="translate(-1187.2)"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default SotiesDescriptionOrnament;
