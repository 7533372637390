import { FC } from "react";

interface LogoStoriesProps {
  color?: string;
  className?: string;
}

const DescriptionHeroOrnament: FC<LogoStoriesProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100.559"
      height="73.185"
      viewBox="0 0 100.559 73.185"
      className={className}
    >
      <g id="flag-destaque" transform="translate(0.671 0.5)">
        <g id="Grupo_462" data-name="Grupo 462">
          <path
            id="Caminho_134"
            data-name="Caminho 134"
            d="M31.506,0,9.965,72.185H0L21.535,0Z"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
        <g
          id="flag-destaque-2"
          data-name="flag-destaque"
          transform="translate(22.625)"
        >
          <path
            id="Caminho_135"
            data-name="Caminho 135"
            d="M428.2,0,406.665,72.185H396.7L418.235,0Z"
            transform="translate(-396.7)"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_464" data-name="Grupo 464" transform="translate(45.091)">
          <path
            id="Caminho_136"
            data-name="Caminho 136"
            d="M822.1,0,800.565,72.185H790.6L812.136,0Z"
            transform="translate(-790.6)"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
        <g id="Grupo_465" data-name="Grupo 465" transform="translate(67.712)">
          <path
            id="Caminho_137"
            data-name="Caminho 137"
            d="M1218.706,0l-21.54,72.185H1187.2L1208.736,0Z"
            transform="translate(-1187.2)"
            fill="none"
            stroke="#ff1430"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default DescriptionHeroOrnament;
