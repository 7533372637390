import { INTERNAL_360_PATH, INTERNAL_360_PATH_MOBILE } from "@/assets";
import { Image360Item } from "@/models";

export const Images360 = (): Image360Item[] => {
  return window.innerWidth <= 1024
    ? Internal360ImagesMobile
    : Internal360Images;
};

export const Internal360Images: Image360Item[] = [
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00000.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00001.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00002.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00003.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00004.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00005.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00006.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00007.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00008.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00009.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00010.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00011.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00012.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00013.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00014.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00015.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00016.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00017.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00018.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00019.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00020.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00021.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00022.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00023.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00024.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00025.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00026.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00027.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00028.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00029.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00030.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00031.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00032.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00033.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00034.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00035.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00036.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00037.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00038.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00039.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00040.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00041.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00042.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00043.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00044.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00045.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00046.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00047.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00048.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00049.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00050.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00051.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00052.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00053.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00054.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00055.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00056.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00057.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00058.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00059.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00060.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00061.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00062.webp` },
  { src: `${INTERNAL_360_PATH}RAM_1500_Classic_Interno_00063.webp` },
];

export const Internal360ImagesMobile: Image360Item[] = [
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00000.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00001.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00002.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00003.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00004.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00005.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00006.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00007.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00008.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00009.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00010.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00011.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00012.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00013.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00014.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00015.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00016.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00017.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00018.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00019.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00020.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00021.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00022.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00023.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00024.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00025.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00026.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00027.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00028.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00029.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00030.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00031.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00032.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00033.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00034.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00035.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00036.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00037.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00038.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00039.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00040.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00041.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00042.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00043.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00044.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00045.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00046.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00047.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00048.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00049.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00050.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00051.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00052.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00053.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00054.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00055.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00056.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00057.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00058.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00059.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00060.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00061.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00062.webp` },
  { src: `${INTERNAL_360_PATH_MOBILE}RAM_1500_Classic_Interno_00063.webp` },
];
