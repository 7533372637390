import { Conditional, SectionElement } from "@/components";
import { metaTags } from "@/constants";
import { PRODUCT_DETAILS_REF, dataMenuLabels } from "@/data/menu.data";
import { FC, useContext } from "react";
import Tabs from "./product-details-tabs-component";
import styles from "./product-details-section.module.scss";
import SwipersDesktop from "./product-details-swiper-component/swiper-product-details-desktop.component";
import SwipersMobile from "./product-details-swiper-component/swiper-product-details-mobile.component";
import { Context } from "@/context";
import {
  allSlides,
  getProductDetails,
  productDetails,
} from "@/data/product-details.data";

export const ProductDetails: FC = () => {
  const { state, dispatch } = useContext(Context);

  const reference = dataMenuLabels().find((c) => c.slug === "tudo-sobre-toro");

  const setReference = (tab?: any) => {
    let reference;
    let suffix;
    const index = !state.layout.isDesktop
      ? state.layout.productDetailsMobileSwiperController[
          state.layout.selectedDetailTab ?? 0
        ]?.activeIndex
      : state.layout.productDetailsSwiperController?.activeIndex;

    if (tab) {
      reference = getProductDetails(productDetails).find(
        (p) => p.id === `data-${tab?.id}`
      );
      if (!state.layout.isDesktop) {
        suffix = tab.children[index ?? 0].slug;
      } else {
        suffix = allSlides[index ?? 0].slug;
      }
    } else {
      suffix = allSlides[index ?? 0].slug;
      reference = getProductDetails(productDetails).find(
        (p) => p.id === `data-${state.layout.currentTab?.id}`
      );
    }

    if (reference) {
      if (state.layout.selectedDetailTab) {
        dispatch({
          type: "SET_CURRENTLY_VISIBLE_NAV",
          payload: { view: reference, suffix: suffix },
        });
      }
    }
  };

  return (
    <SectionElement
      id="ProductDetails"
      navReference={reference}
      className={styles.container}
    >
      <Conditional notOn="mobile">
        <div className={styles.white}></div>
      </Conditional>

      <p className={styles.sectionName}>
        TUDO SOBRE A <Conditional mobile={<br />} /> <em>FIAT TORO</em>
      </p>
      <Conditional
        desktop={
          <>
            <Tabs />
            <SwipersDesktop setReference={setReference} />
          </>
        }
        mobile={<SwipersMobile setReference={setReference} />}
      />
    </SectionElement>
  );
};
