import { PREFIX } from '@/utils/imagePrefixes';

const BANNER_125_ANOS_PREFIX = `${PREFIX}banner-125/`;

const BANNER_125_ANOS_ASSETS = {
  mobile: {},
  desktop: {
    background: `${BANNER_125_ANOS_PREFIX}background/fiat-125-anos@3x.webp`,
    model: `${BANNER_125_ANOS_PREFIX}model/fiat-toro-125-anos@3x.webp`,
    couple: `${BANNER_125_ANOS_PREFIX}couple/casal-125-fiat@3x.webp`,
    lettering: `${BANNER_125_ANOS_PREFIX}lettering-125-anos/lettering-125-anos@3x.webp`,
    logo: `${BANNER_125_ANOS_PREFIX}logo-125-anos/logo-125-anos@3x.webp`,
  },
};

export default BANNER_125_ANOS_ASSETS;
